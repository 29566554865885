.modal {
  width: 100% !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.avatar-grid,
.background-grid {
  display: grid !important;
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
  gap: 12px !important;
  padding: 16px !important;
  width: 100% !important;
}

.avatar-item,
.background-item {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  cursor: pointer !important;
  padding: 8px !important;
  border-radius: 8px !important;
  transition: background-color 0.2s !important;
  width: 100% !important;
}

.avatar-item:hover, .background-item:hover {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.avatar-item img,
.background-item img {
  width: 100% !important;
  height: 150px !important;
  object-fit: cover !important;
  border-radius: 4px !important;
}

.avatar-item p,
.background-item p {
  margin-top: 8px !important;
  font-size: 12px !important;
  color: #666 !important;
  text-align: center !important;
  width: 100% !important;
}

@media (max-width: 768px) {
  .avatar-grid,
  .background-grid {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (max-width: 480px) {
  .avatar-grid,
  .background-grid {
    grid-template-columns: repeat(2, 1fr) !important;
  }
} 