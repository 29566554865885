.composer-container {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
}

.composer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
  margin-right: 1rem;
}

.control-panel {
  display: flex;
  gap: 1rem;
}

.control-button {
  background-color: #585a96;
  color: #fff;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
}

.composer-layout {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  margin-right: 1rem;
}

.column {
  flex: 1;
  padding: 1rem;
  background-color: #e5e5e5;
  border: 1px solid #585a96;
  border-radius: 8px;
  overflow: hidden; 
  display: flex;
  justify-content: center;
}

.column img {
    max-width: 150%;
    height: 90%;
    object-fit: scale-down;
  }
  
  .column-1 img {
    max-width: 120%;
    height: 75%;
    object-fit: scale-down;
    margin-left: 30px;
    margin-top: 45px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .column-2 img {
    max-width: 250%;
    height: 90%;
    object-fit: scale-down;
    margin-top: 40px;
    margin-left: -50px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .preview-image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }

  .column-3 h2 {
    margin-bottom: 1rem;
    text-align: center;
  }

  .column-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }







.dark .composer-header,
.dark .column {
  background-color: #333;
  color: #fff;
}

@media (max-width: 768px) {
  .composer-layout {
    flex-direction: column;
  }
} 

.column-1, .column-2 {
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}