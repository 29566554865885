.background-image {
  border-radius: 5px !important;
  cursor: pointer;
  width: 140% !important;
  height: 130% !important;
  object-fit: cover !important;
  background-color: #fff8dc !important;
  padding: 0 !important;
  margin: 0 !important;
  display: block !important;
  transform: scale(1.4) translateY(10px) !important;
}
.background-dialog-button {
    width: 30%;
  }
.background-image:hover {
  outline: 2px solid #585a96;
  border-radius: 5px;
}

.selected {
  border: 2px solid #fff;
  outline: 3px solid #585a96;
}

.background-grid-container {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
  gap: 12px !important;
  width: 100% !important;
  padding: 16px !important;
}

.background-item {
  width: 200px !important;
  height: 180px !important;
  text-align: center !important;
  padding: 8px !important;
  margin-bottom: 8px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-start !important;
  background-color: #a6a3ff !important;
  border-radius: 5px !important;
  overflow: hidden !important;
  padding-top: 10px !important;
}

.background-dialog-surface {
  min-width: 1200px !important;
  max-width: 90vw !important;
  height: 80vh !important;
  overflow-y: auto !important;
}

.dialog-content {
  width: 100% !important;
  padding: 20px !important;
}

/* Estilos responsivos */
@media screen and (max-width: 1400px) {
  .background-dialog-surface {
    min-width: 95vw !important;
  }
}

@media screen and (max-width: 1200px) {
  .background-grid-container {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}

@media screen and (max-width: 1024px) {
  .background-grid-container {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

@media screen and (max-width: 768px) {
  .background-grid-container {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  
  .background-image {
    height: 90px !important;
  }
}

@media screen and (max-width: 480px) {
  .background-grid-container {
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 8px !important;
  }
  
  .background-image {
    height: 80px !important;
  }
}

@media screen and (max-width: 912px) {
  .background-grid-container {
    grid-template-columns: repeat(4, 1fr) !important;
  }

  .background-image {
    width: 100px !important;
    height: 120px !important;
    transform: scale(1.2) translateY(5px) !important;
  }

  .background-item {
    width: 120px !important;
    height: 140px !important;
  }

  .background-dialog-surface {
    min-width: 80vw !important;
    max-width: 80vw !important;
    height: 70vh !important;
  }
}

.background-item p {
  display: none !important;
}
