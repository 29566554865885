.request-table th {
  padding: 8px;
}

.request-table th.fecha {
  width: 150px;
}

.request-table th.avatar,
.request-table th.fondo,
.request-table th.idioma,
.request-table th.género,
.request-table th.video,
.request-table th.acciones {
  width: 100px;
}

.request-table th.prompt {
  width: 200px;
}

.request-table th.clase {
  width: 150px;
}

.request-table th.estado {
  width: 20px;
  margin-right: 10px;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
} 