.preview-button {
  margin: 7px !important;
  background-color: #585a96 !important;
  color: #fff !important;
}



.generate-voice-button:disabled,
.generate-video-button:disabled,
.preview-button:disabled {
  background-color: #7a7ccb !important;
}

#mainLayout {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.column {
  flex: 1 1 45%;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  #mainLayout {
    flex-direction: column;
  }

  .control-panel {
    width: auto;
    margin-left: 0;
  }

  .control-button {
    height: 35px;
    padding: 0 8px;
    font-size: 12px;
  }

  .composer-header {
    flex: 1;
  }
}

@media (min-width: 769px) and (max-width: 912px) {
  #mainLayout {
    flex-direction: row;
    justify-content: space-between;
  }
}

#col1 {
  flex: 1;
  margin-left: 20px;
}

.video-preview-title {
  font-family: Arial, sans-serif;
  font-size: 1.4rem !important;
  font-weight: 500;
  color: hsl(0, 0%, 9%);
}

.video-preview-title-dark {
  font-family: Arial, sans-serif;
  font-size: 1.2rem !important;
  font-weight: 500;
  color: hwb(0 100% 0%);
}

.text-style {
  font-family: Arial, sans-serif;
  font-size: 1.2rem !important;
  font-weight: 500;
  color: hsl(0, 0%, 9%);
}

.text-style-Prompt {
  font-family: Arial, sans-serif;
  font-size: 1.1rem !important;
  font-weight: 500;
  color: hsl(0, 0%, 9%);
}

.final-adjustments-title {
  font-family: Arial, sans-serif;
  font-size: 1.6rem !important;
  font-weight: 500;
  color: hsl(0, 0%, 9%);
}

.final-adjustments-title-dark {
  font-family: Arial, sans-serif;
  font-size: 1.6rem !important;
  font-weight: 500;
  color: hwb(0 100% 0%);
}

.composer-container {
  display: flex;
  flex-direction: column;
}

.composer-header {
  flex: 3 !important;
  border-radius: 10px;
  border-color: #585a96 !important;
  border-top: 1px solid #585a96 !important;
  border-left: 1px solid #585a96 !important;
  border-right: 1px solid #585a96 !important;
}

.control-panel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border-color: #585a96 !important;
  border-top: 1px solid #585a96 !important;
  border-left: 1px solid #585a96 !important;
  border-right: 1px solid #585a96 !important;
  border-bottom: 1px solid #585a96 !important;
  background-color: #f0f0f0;
  width: 95%;
  margin-left: -15px;
}

.dark .control-panel {
  background-color: #333;
}

.composer-layout {
  display: flex;
  flex-wrap: wrap;
}

.control-button {
  height: 40px;
  padding: 0 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 431px) {
  .control-panel {
    width: calc(101% - 20px) !important;
    margin-left: 0 !important;
    justify-content: center !important;
  }

  .control-button {
    height: 30px;
    width: 30px;
    padding: 0 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .column {
    margin-left: -2px;
    width: calc(100% - 40px) !important;
    margin-top: 20px;
  }

  .row-1 {
    margin-left: -20px;
    justify-content: left;
  }
  .row-2 {
    margin-left: -20px;
    justify-content: left;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .composer-layout {
    flex-direction: column;
  }

  .composer-header
  {
    width: 90% !important;
    margin-left: 0px !important;
  }

  .control-panel {
    margin-top: 10px;
    margin-left: 0px !important;
    width: 93.5% !important;
  }
  .column {
    margin-left: 0px !important;
    width: 90% !important;
  }
}
