.sidebar {
  position: fixed;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: #f5f5f5;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
  transition: bottom 0.3s ease;
  overflow-y: auto;
  z-index: 1000;
  padding: 30px 15px;
}

.sidebar.open {
  bottom: 0;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Media query para pantallas móviles */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: 70%;
  }
}

.dark-mode::placeholder {
  color: #ccc; /* Color del placeholder en modo oscuro */
}

.light-mode::placeholder {
  color: #666; /* Color del placeholder en modo claro */
} 